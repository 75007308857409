
import axios from '@/http'
import { defineComponent, ref, reactive, computed, inject, toRef } from 'vue'
import { notifyInstance, modalInstance } from '@/type'
import { useStore } from 'vuex'
import DropdownCheckboxItem from '@/views/components/dropdownCheckboxItem.vue'
import DropdownCheckboxToggle from '@/views/components/dropdownCheckboxToggle.vue'
import usePagination from '@/plugins/usePagination'

interface productList {
  imgUrl: string
  isVisible: boolean
  price: number
  productId: number
  productName: string
  productNo: string
}

export default defineComponent({
  components: {
    'dropdown-checkbox-toggle': DropdownCheckboxToggle,
    'dropdown-checkbox-item': DropdownCheckboxItem
  },
  setup() {
    const notify = inject('notify') as notifyInstance
    const modal = inject('modal') as modalInstance

    const store = useStore()
    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)

    const keyword = ref('')
    const keywordTypes = ref<string[]>([])
    const productLists = ref<productList[]>([])

    function formatNumber(number: any) {
      return number === undefined ? 0 : parseInt(number).toLocaleString('en-US')
    }

    let pagination: {
      next: () => any
      pre: () => any
      go: (goPage: number) => any
      getData: () => any
    } | null = null

    const paginationData = reactive({
      page: 1,
      pageSize: 20,
      lastPage: 1,
      total: 0,
      products: ref(new Array<productList>())
    })

    function transferKeywordTypesToObject() {
      const res: { [key: string]: any } = {}

      keywordTypes.value.forEach((keywordType, index) => {
        res[`keywordType[${index}]`] = keywordType
      })

      return res
    }

    function callProductListsAPI(payload?: any) {
      const paginationSetting = {
        page: 1,
        pageSize: 20,
        api: `/store/${storeId.value}/products`,
        ...(payload !== undefined ? { payload } : {})
      }

      const initPagination = usePagination(paginationSetting).then((res) => {
        pagination = res
      })

      Promise.all([initPagination]).then(function () {
        getData()
      })
    }

    callProductListsAPI()

    function nextPage() {
      const fetchData = pagination?.next()
      fetchData.then(() => {
        getData()
      })
    }

    function prePage() {
      const fetchData = pagination?.pre()
      fetchData.then(() => {
        getData()
      })
    }

    function goPage(page: number) {
      const fetchData = pagination?.go(page)
      fetchData.then(() => {
        getData()
      })
    }

    function getData() {
      const data = pagination?.getData()
      paginationData.page = data.currentPage
      paginationData.lastPage = data.lastPage
      paginationData.pageSize = data.pageSize
      paginationData.total = data.total
      paginationData.products = data.items
    }

    function searchLists() {
      if (keyword.value !== '' && keywordTypes.value.length === 0) {
        modal({
          content: '請選擇關鍵字類別',
          confirmBtnText: '確定',
          classType: 'alert',
          cancelBtn: false
        }).show()

        return
      }

      callProductListsAPI({
        ...(keyword.value ? { keyword: keyword.value } : {}),
        ...transferKeywordTypesToObject()
      })
    }

    function switchAppVisible(productId: number, isChecked: boolean) {
      axios
        .patch(`/store/${storeId.value}/product/${productId}`, {
          isVisible: isChecked ? 1 : 0
        })
        .then(() => {
          notify(
            {
              classType: 'success',
              message: '儲存成功',
              closeAble: false
            },
            3000
          )
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `儲存失敗 ${err.response.errors.detail}`,
              closeAble: true
            },
            3000
          )
        })
    }

    return {
      keyword,
      keywordTypes,
      productLists,
      formatNumber,
      paginationData,
      prePage,
      nextPage,
      goPage,
      searchLists,
      modal,
      switchAppVisible
    }
  }
})
