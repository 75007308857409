
import type { PropType } from 'vue'
import { defineComponent, ref, onMounted, onUnmounted, computed } from 'vue'
import { useComponentSlot } from '@/plugins/useComponentSlot'
import DropdownCheckboxItem from './dropdownCheckboxItem.vue'

export default defineComponent({
  props: {
    modelValue: {
      type: Array as PropType<(string | number)[]>,
      required: true
    },
    placeholder: { type: String, required: true }
  },
  setup(props, { emit }) {
    // 渲染menu清單
    const { virtualDom } = useComponentSlot(
      DropdownCheckboxItem,
      'dropdownCheckboxToggle 的子標籤必須是 dropdownCheckboxItem!'
    )

    // 開關選單
    const isDropdownMenuOpen = ref(false)
    const toggleDropdownMenu = () => {
      isDropdownMenuOpen.value = !isDropdownMenuOpen.value
    }
    const closeCollapse = () => {
      isDropdownMenuOpen.value = false
    }

    onMounted(() => {
      window.addEventListener('click', closeCollapse)
    })

    onUnmounted(() => {
      window.removeEventListener('click', closeCollapse)
    })

    // 蒐集點選的選項
    const picks = computed<(string | number)[]>({
      get() {
        return [...props.modelValue]
      },
      set(value) {
        emit('update:modelValue', value)
      }
    })

    const handleUpdatePicks = (pick: number | string) => {
      if (!picks.value.includes(pick)) {
        picks.value = [...picks.value, pick]
      } else {
        picks.value = picks.value.filter((item) => item !== pick)
      }
    }

    return {
      virtualDom,
      isDropdownMenuOpen,
      toggleDropdownMenu,
      picks,
      handleUpdatePicks
    }
  }
})
