import { useSlots, computed } from 'vue'

export const useComponentSlot = (childComponent: any, alertMsg: string) => {
  const defaults = computed(() => (useSlots() as any).default())

  const handleSlots = (
    symbolCall: (item: any) => void,
    singleCall: (item: any) => void
  ) => {
    defaults.value.forEach((item: any) => {
      const isSymbol = typeof item.type === 'symbol'

      if (isSymbol && (item.children === 'v-if' || item.children === '')) return

      // 可能用v-for跑
      if (isSymbol && Array.isArray(item.children)) {
        symbolCall(item)
      } else {
        singleCall(item)
      }
    })
  }

  const virtualDom = computed(() => {
    const res: any[] = []

    handleSlots(
      (item) => {
        item.children.forEach((child: any) => {
          if (child.type !== childComponent) {
            throw new Error(alertMsg)
          }
        })
        res.push(...item.children)
      },
      (item) => {
        if (item.type !== childComponent) {
          throw new Error(alertMsg)
        }
        res.push(item)
      }
    )

    return res
  })

  return {
    handleSlots,
    virtualDom
  }
}
