
import { defineComponent } from 'vue'
import AppNav from './components/nav.vue'
import Sidebar from '@/views/store/marketing/components/sidebar.vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: { 'app-nav': AppNav, Sidebar },
  setup() {
    const route = useRoute()

    return {
      route
    }
  }
})
