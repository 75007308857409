import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "c-select" }
const _hoisted_2 = { class: "c-dropdownCheckboxMenu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "l-dropdownCheckboxMenu",
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleDropdownMenu && _ctx.toggleDropdownMenu(...args)), ["stop"]))
  }, [
    _createElementVNode("button", _hoisted_1, [
      _createElementVNode("span", null, _toDisplayString(_ctx.placeholder) + "(" + _toDisplayString(_ctx.picks.length) + ")", 1)
    ]),
    _withDirectives(_createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.virtualDom, (item) => {
        return (_openBlock(), _createBlock(_resolveDynamicComponent(item), {
          key: item.key,
          onUpdatePicks: _ctx.handleUpdatePicks
        }, null, 8, ["onUpdatePicks"]))
      }), 128))
    ], 512), [
      [_vShow, _ctx.isDropdownMenuOpen]
    ])
  ]))
}