
import axios from '@/http'
import { defineComponent, ref, computed, inject } from 'vue'
import { useStore } from 'vuex'
import { notifyInstance } from '@/type'
import NestDraggable from '@/views/components/nestDraggable.vue'
import notification from '@/views/components/notification.vue'

interface categoryItem {
  id: number
  isVisible: boolean
  name: string
}

interface draggableCategoryItem extends categoryItem {
  tasks: (categoryItem & { tasks: [] })[]
}

export default defineComponent({
  components: {
    'nested-draggable': NestDraggable,
    notification
  },
  setup() {
    const notify = inject('notify') as notifyInstance

    const store = useStore()

    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)

    const lists = ref<draggableCategoryItem[]>([])
    const draggableDisable = ref(false)
    const teleportTarget = ref('')
    const messageVisible = ref(false)

    function showMessage(id: string) {
      teleportTarget.value = `#${id}`
      messageVisible.value = true
    }

    function updateListsSort(e: any) {
      const activeItem = e.to.children[e.newIndex]
      const id = activeItem.querySelector('[data-message]').getAttribute('id')
      showMessage(id)

      function transferToAPIParams(lists: draggableCategoryItem[]) {
        const result: { categoryId: number; parentId: number | null }[] = []

        function transferIds(items: any[], parentId: number | null) {
          for (const item of items) {
            result.push({ categoryId: item.id, parentId })
            if (item.tasks && item.tasks.length > 0) {
              transferIds(item.tasks, item.id)
            }
          }
        }

        transferIds(lists, null)
        return result
      }

      const params = transferToAPIParams(lists.value)

      axios
        .post(`store/${storeId.value}/category/sort`, { categories: params })
        .then(() => {
          notify(
            {
              classType: 'success',
              message: '儲存成功',
              closeAble: false,
              inner: true
            },
            800
          )
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `儲存失敗 ${err.response.errors.detail}`,
              closeAble: true,
              inner: true
            },
            800
          )
        })
    }

    function switchAppVisible(
      categoryId: number,
      isChecked: boolean,
      index: number,
      parentIndex?: number
    ) {
      const message =
        parentIndex !== undefined
          ? `item-${parentIndex}-${index}`
          : `item-${index}`

      showMessage(message)

      const parentId: number | null =
        parentIndex !== undefined ? lists.value[parentIndex].id : null

      axios
        .patch(`/store/${storeId.value}/category/${categoryId}`, {
          isVisible: isChecked ? 1 : 0,
          parentId
        })
        .then(() => {
          notify(
            {
              classType: 'success',
              message: '儲存成功',
              closeAble: false,
              inner: true
            },
            800
          )
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `儲存失敗 ${err.response.errors.detail}`,
              closeAble: true,
              inner: true
            },
            800
          )
        })
    }

    function transferListFormat(
      categories: (categoryItem & { parentId: number | null })[]
    ) {
      categories.forEach((category) => {
        const isParent = category.parentId === null
        const list = {
          id: category.id,
          isVisible: category.isVisible,
          name: category.name,
          tasks: [] as any
        }

        if (isParent) {
          lists.value.push(list)
        } else {
          const parentIndex = lists.value.findIndex(
            (list) => list.id === category.parentId
          )
          lists.value[parentIndex].tasks.push(list)
        }
      })
    }

    function fetchCategories() {
      axios
        .get(`store/${storeId.value}/categories`)
        .then((res) => {
          transferListFormat(res.data)
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `資訊載入失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })
    }

    fetchCategories()

    return {
      lists,
      draggableDisable,
      switchAppVisible,
      updateListsSort,
      teleportTarget,
      messageVisible
    }
  }
})
