
import { computed, defineComponent, inject, reactive, ref } from 'vue'
import axios from '@/http'
import { notifyInstance, storePrinter } from '@/type'
import { useStore } from 'vuex'
import Fixed from '@/views/components/fixed.vue'
export default defineComponent({
  components: {
    Fixed
  },
  setup() {
    const notify = inject('notify') as notifyInstance

    const store = useStore()

    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)

    const showLogo = ref(false)
    const showBottom = ref(false)
    const file = ref(null)

    const storePrinter = reactive({} as storePrinter)
    const formData = reactive(new FormData())
    formData.append('_method', 'PATCH')
    formData.append('type', 'info')

    const handleUploadImg = (event: any) => {
      const input = event.target

      if (input.files) {
        if (input.files[0].type !== 'image/png') {
          notify(
            {
              classType: 'error',
              message: '上傳圖檔失敗，限制 PNG 格式',
              closeAble: true
            },
            3000
          )
          return
        }

        if (input.files[0].size > 200 * 1024) {
          notify(
            {
              classType: 'error',
              message: '上傳圖檔失敗，檔案超過 200 KB',
              closeAble: true
            },
            3000
          )
          return
        }

        const reader = new FileReader()
        reader.onload = (event: any) => {
          storePrinter.src = event.target.result
          file.value = input.files[0]
          formData.append('image', input.files[0])
        }
        reader.readAsDataURL(input.files[0])
      }
    }

    function init() {
      axios
        .get(`/system/printer/${storeId.value}`)
        .then((res) => {
          Object.assign(storePrinter, res.data.storePrinter)
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `初始化失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })
    }

    function save() {
      formData.delete('storePrinter')
      formData.append(
        'storePrinter[isPrintStore]',
        JSON.stringify(storePrinter.isPrintStore)
      )
      formData.append(
        'storePrinter[isPrintEmployee]',
        JSON.stringify(storePrinter.isPrintEmployee)
      )
      formData.append(
        'storePrinter[isPrintInfo]',
        JSON.stringify(storePrinter.isPrintInfo)
      )
      formData.append(
        'storePrinter[printStore]',
        storePrinter.printStore === null
          ? ''
          : storePrinter.printStore!.toString()
      )
      formData.append(
        'storePrinter[printInfo]',
        storePrinter.printInfo === null
          ? ''
          : storePrinter.printInfo!.toString()
      )

      axios
        .post(`/system/printer/${storeId.value}`, formData, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })
        .then((res) => {
          notify(
            {
              classType: 'success',
              message: '儲存成功',
              closeAble: false
            },
            3000
          )
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `儲存失敗，${err.response.errors.detail}`,
              closeAble: false
            },
            3000
          )
        })
    }

    function cancel() {
      init()
    }

    init()

    return {
      handleUploadImg,
      storePrinter,
      save,
      cancel,
      showLogo,
      showBottom,
      file
    }
  }
})
