
import { computed, defineComponent, inject } from 'vue'
import { notifyInstance } from '@/type'
import { useStore } from 'vuex'

export default defineComponent({
  setup() {
    const notify = inject('notify') as notifyInstance

    const store = useStore()
    const shopsUrl = computed(() => store.getters.shopsUrl)

    function copy() {
      var input = document.createElement('input')
      input.setAttribute(
        'value',
        shopsUrl.value + 'index/member_login?type=posLogin'
      )
      document.body.appendChild(input)
      input.select()

      try {
        var copyResult = document.execCommand('copy')
      } catch (e) {
        copyResult = false
      }

      document.body.removeChild(input)

      if (copyResult) {
        notify(
          {
            classType: 'success',
            message: '複製成功',
            closeAble: true
          },
          6000
        )
      } else {
        notify(
          {
            classType: 'error',
            message: '複製連結失敗',
            closeAble: true
          },
          6000
        )
      }

      return copyResult
    }

    return {
      copy,
      shopsUrl
    }
  }
})
