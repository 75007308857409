
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  props: ['propEmployees', 'propGroups'],
  setup() {
    const route = useRoute()

    return {
      route
    }
  }
})
