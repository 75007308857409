
import draggable from 'vuedraggable'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  emits: ['update'],
  props: {
    tasks: {
      required: true,
      type: Array
    },
    limit: {
      type: Boolean,
      default: false
    },
    disable: {
      type: Boolean,
      default: false
    },
    parentIndex: {
      type: Number
    }
  },
  components: {
    draggable
  },
  setup(props, { emit }) {
    // 檢查拖曳元件若含有第二層只能在第一層移動
    const checkMove = (e: any) => {
      let dropDepth = 0
      let dropElement = e.to

      while (dropElement) {
        if (dropElement.classList.contains('l-sortbar')) {
          dropDepth++
        }
        dropElement = dropElement.parentElement
      }

      const draggedElements = e.dragged.children

      for (let i = 0; i < draggedElements.length; i++) {
        if (draggedElements[i].classList.contains('l-sortbar')) {
          // 超過2層無法拖曳：拖曳元件有子層且drop的層級超過1
          if (dropDepth > 1 && draggedElements[i].children.length > 0) {
            return false
          }
        }
      }

      return true
    }

    const rootElement = ref()
    const $main = document.querySelector('[data-main-wrap]') as HTMLBodyElement
    let $draggable: any = null

    const startDrag = () => {
      // 快接近捲軸底部加下面空間，防止捲軸跳動導致無法偵測第二層排序
      const scrollTop = window.scrollY || document.documentElement.scrollTop
      const windowHeight = window.innerHeight
      const documentHeight = document.documentElement.scrollHeight

      if (scrollTop + windowHeight + 100 >= documentHeight) {
        $main.style.marginBottom = '500px'
      }

      // 新增子層預設虛線
      $draggable =
        rootElement.value.targetDomElement.querySelectorAll('[data-draggable]')
      $draggable.forEach((element: Element) => {
        element.classList.add('is-drag')
      })
    }

    const endDrag = (e: any) => {
      $main.style.marginBottom = '0'
      $draggable.forEach((element: Element) => {
        element.classList.remove('is-drag')
      })
      emit('update', e)
    }

    return {
      checkMove,
      startDrag,
      endDrag,
      rootElement
    }
  },
  name: 'nested-draggable'
})
